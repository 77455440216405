<!--输入手机 验证码 组件复用-->
<template>
    <div class="phone-code-container">
        <div class="item phone-item">
            <div class="label" @click="isShowCode = true">
                +{{ codeData }}
                <van-image class="icon" width="12" height="12"
                           src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/register/xiala.png"/>
            </div>
            <van-field  v-model="phoneNumber" center clearable type="tel"  placeholder="请输入手机号码"/>
        </div>
        <div class="item code-item">
            <div class="label">
                <van-image class="password-icon" width="20" height="20" src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/register/password.png"/>
            </div>
            <van-field v-model="codeValue" center clearable placeholder="请输入验证码">
                <template #button>
                    <van-button class="code-btn" size="small" type="primary"
                                :disabled="isSend ? true : false"
                                @click="onSendCode">
                        {{ isSend ? times + "s后重发" : "发送验证码" }}
                    </van-button >
                </template>
            </van-field>
        </div>

        <!-- 弹框 -->
        <van-popup v-model="isShowCode" position="bottom">
            <van-picker  show-toolbar
                         :columns="codeActions"
                         @cancel="isShowCode = false"
                         @confirm="onConfirmCode"
            />
        </van-popup>

        <!-- 验证码多次验证弹框 -->
        <auth  v-if="showAuth" :showAuth.sync="showAuth"  :phone-number="phoneNumber"  @getCode="onSendCode"></auth>
    </div>
</template>

<script>
    import {
        getPhoneCode,
        getPhoneNumber,
    } from "@/api/service";
    import Auth from "@/components/authRestriction/authRestriction.vue";

    export default {
        name: "PhoneCode",
        components: {
            Auth
        },
        props: {
            value: {
                type: Object,
                default(){return{
                    phone: '',
                    code: '',
                    codeData: ''
                }}
            },
        },
        data() {
            return {
                showAuth: false,

                //区号
                codeActions: [],
                isShowCode: false,

                isSend: false,
                times: 60,
            }
        },
        computed: {
            //区号
            codeData: {
                get(){
                    return this.value.codeData
                },
                set(v){
                    this.value.codeData = v
                }
            },
            //手机号
            phoneNumber:{
                get(){
                    return this.value.phone;
                },
                set(v){
                    this.value.phone = v
                }
            },
            //验证码
            codeValue:{
                get(){
                    return this.value.code;
                },
                set(v){
                    this.value.code = v
                }
            },
        },
        created() {
            getPhoneCode().then((res) => {
                this.codeData = res.data[0].phoneCode;
                let data = [];
                res.data.forEach((item) => {
                    data.push({
                        text: item.countryName + "+" + "(" + item.phoneCode + ")",
                        code: item.phoneCode,
                    });
                });
                this.codeActions = data;
            });
            this.timeStamp = new Date().getTime();
        },
        methods: {
            //获取区域号
            onConfirmCode(value) {
                this.codeData = value.code;
                this.isShowCode = false;
            },
            onSendCode(codeResult) {
                if (!this.phoneNumber) {
                    this.$failToast("请输入手机号码");
                    return;
                }
                getPhoneNumber({
                    phoneNumber: this.phoneNumber,
                    code: this.codeData,
                    securityCheckVal: parseInt(codeResult),
                    isRegister: 1,
                }).then((res) => {
                    if (res.code === 19) {
                        //需要图片验证码校验
                        this.showAuth = true;
                    } else if (res.code === 20) {
                        this.$failToast("图片验证码错误,请重新输入");
                    } else if (res.code == 1003) {
                       this.$emit('hasUser', this.phoneNumber)
                    } else {
                        this.isSend = true;
                        this.timer = setInterval(() => {
                            this.times--;
                            if (this.times === 0) {
                                this.isSend = false;
                                this.times = 60;
                                clearInterval(this.timer);
                            }
                        }, 1000);
                        this.$successToast("发送成功");
                        this.isSend = true;
                    }
                });
            },
        }
    }
</script>

<style lang="scss" scoped>
    .container{
        margin: 0 auto;
    }
    .item {
        height: 40px;
        overflow: hidden;
        display: flex;
        align-items: center;
        background: #F2F2F2;

        .van-field{
            background: #F2F2F2;
        }

        .label{
            width: 60px;
            font-size: 20px;
            padding: 0 5px;
        }

        .password-icon{
            position: relative;
            left: 10px;
        }
    }

    .phone-item{
        margin-bottom: 10px;
        .label{
            display: flex;
            align-items: flex-end;
        }
    }


</style>
