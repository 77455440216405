<template>
	<div class="container">
		<div class="coupon-dialog">
			<div class="title-box">
				<div class="title">
					请按照下方图片填写答案
				</div>
				<van-icon name="close" class="iconqingchu" @click="close()" />
			</div>
			<div class="image-box" @click="updateUrl">
				<img class="inline" :src="authUrl" style="width: 155px; height: 50px;" />
			</div>
			<div class="login-box">
				<img class="inline" src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/register/password.png" width="20" height="20" />
				<div class="" style="width: 10px;height: 1px;">
				</div>
				<div class="code-btn">
					<van-field  v-model="form.codeResult" center clearable type="text"  placeholder="请输入验证码"/>
				</div>
			</div>
			<div class="login-box" :class="isComplete?'orange-bgc':''" @click="complete">
				完成
			</div>
		</div>
	</div>
</template>
<script>

	export default {
		name: 'authRestriction',
		props: {
			showAuth: {
				type: Boolean,
				default: false
			},
			phoneNumber:{
				type: String,
				default: ''
			}
		},
		data() {
			return {
				// 验证码地址
				timeStamp: '',
				form: {
					codeResult: null
				},
				apiUrl: process.env.VUE_APP_BASE_API,
			}
		},
		computed: {
			authUrl() {
				return `${this.apiUrl}captcha/verification?phone=${this.phoneNumber}&timeStamp=${this.timeStamp}`
			},
			isComplete() {
				let flag = false
				if (!this.form.codeResult){
					flag = false
				}else{
					flag = true
				}
				return flag
			}
		},
		created() {
			this.timeStamp = new Date().getTime()
		},
		methods: {
			updateUrl() {
				this.timeStamp = new Date().getTime()
			},
			close() {
				this.$emit('update:showAuth', false)
			},
			complete() {
				if(this.form.codeResult) {
					this.$emit('update:showAuth', false)
					this.$emit('getCode', this.form.codeResult)
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.container {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 9999;
		width: 100%;
		height: 100vh;
		display: flex;
		justify-content: center;
		align-items: center;

		background: rgba($color: #000000, $alpha: .5);

		.coupon-dialog {
			width: 325px;
			height: 330px;
			border-radius: 10px;
			overflow: hidden;
			background: #fff;
			padding: 20px 10px;

			.title-box {
				display: flex;
				justify-content: space-around;
				align-items: center;

				.title {
					font-size: 20px;
					font-family: PingFang SC;
					font-weight: 700;
					color: #333333;
				}

				.iconqingchu {
					font-size: 20px;
					color: #bebebe;
				}
			}

			.image-box {
				margin-top: 20px;
				height: 100px;
				display: flex;
				justify-content: center;
			}

			.van-field{
				background: #F2F2F2;
			}
			.login-box {
				font-size: 18px;
				height: 45px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 22.5px;
				border: 1px solid transparent;
				border-radius: 22.5px;
				overflow: hidden;
				background-color: #f2f2f2;
				color: #fff;
				.code-btn {
					width: 250px;
					color: #000000;
				}
			}

			.orange-bgc {
				background-color: #F67F58;
				border: 1px solid #F67F58;
			}

		}
	}
</style>
