import request from '@/utils/request'

/**
 * 获取新人优惠券列表
 * @param query
 */
export function getNewUserCoupon(query) {
    return request({
        url: '/order/coupon/getNewUserCoupon',
        method: 'get',
        params: query
    })
}


/**
 * 手机验证码登录
 * @param data
 */
export function phoneCodeRegister(data) {
    return request({
        url: '/user/phoneCodeRegister',
        method: 'post',
        data: data
    })
}








